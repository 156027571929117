<template>
  <RecordViewWrapper>
    <sdPageHeader :title="formState.title">
      <template #subTitle>
        <sdButton class="btn-add_new" size="default" type="primary" v-if="checkPermission('create kpi korporat')">
          <router-link :to="'/kpi/kpi-korporat-add/' + formState.month + '/' + formState.year">
            <sdFeatherIcons type="plus" size="14" /> Tambah Perspektif
          </router-link>
        </sdButton>
      </template>
    </sdPageHeader>

    <Main>
      <a-row :gutter="15">
        <a-col :sm="24" :xs="24">
          <sdCards headless>
            <a-form
              layout="horizontal"
              :model="formState"
              :label-col="labelCol"
              :wrapper-col="wrapperCol"
              labelAlign="left"
            >
              <a-form-item name="month_year" label="Bulan" style="margin-bottom: 0;">
                <a-month-picker
                  v-model:value="formState.month_year"
                  placeholder="Pilih Bulan"
                  format="MM-YYYY"
                  valueFormat="YYYY-MM"
                  @change="handleMonthChange"
                  style="width: 100%"
                />
              </a-form-item>
            </a-form>
          </sdCards>
        </a-col>

        <a-col class="w-100" :md="24">
          <sdCards headless>
            <div v-if="isLoading" class="spin">
              <a-spin />
            </div>

            <div v-else>
              <div class="table-data-view">
                <a-table
                  :columns="columns"
                  :pagination="false"
                  :dataSource="dataSource"
                  :rowClassName="record => (record.is_parent ? 'text-strong' : '')"
                />
              </div>
            </div>
          </sdCards>
        </a-col>
      </a-row>

      <a-modal v-model:visible="modalState.visible" title="Konfirmasi">
        <a-form
          layout="horizontal"
          :model="formModalState"
          :label-col="labelCol"
          :wrapper-col="wrapperCol"
          labelAlign="left"
          :rules="modalRules"
          id="unapproveForm"
          @finish="doUnapprove"
        >
          Apakah Anda yakin ingin melakukan unapprove {{ modalState.type }} {{ modalState.kpi_korporat }}
          {{ modalState.month }} {{ modalState.year }}?
          <a-form-item name="alasan" label="Alasan">
            <a-input v-model:value="formModalState.alasan" placeholder="Masukkan Alasan" />
          </a-form-item>
        </a-form>
        <template #footer>
          <a-button key="back" @click="handleCancel">Batal</a-button>
          <a-button class="sDash_form-action__btn" type="primary" htmlType="submit" form="unapproveForm">
            {{ modalState.isLoading ? 'Harap Menunggu...' : 'Unapprove' }}
          </a-button>
        </template>
      </a-modal>
    </Main>
  </RecordViewWrapper>
</template>

<script>
import { RecordViewWrapper } from '../../../components/crud/style';
import { computed, onMounted, reactive } from 'vue';
import { Main } from '../../styled';
import { useStore } from 'vuex';
import { Modal } from 'ant-design-vue';
import { useRoute } from 'vue-router';
import moment from 'moment';
import { DataService } from '@/config/dataService/dataService';
import { getItem } from '@/utility/localStorageControl';
import axios from 'axios';

const columns = [
  {
    title: '#',
    dataIndex: 'number',
    key: 'number',
  },
  {
    title: 'Indikator Kinerja',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: '',
    dataIndex: 'kpi_action',
    key: 'kpi_action',
  },
  {
    title: 'Polarisasi',
    dataIndex: 'polarisasi',
    key: 'polarisasi',
  },
  // {
  //   title: 'Formula',
  //   dataIndex: 'formula_description',
  //   key: 'formula_description',
  // },
  {
    title: 'Satuan',
    dataIndex: 'satuan_kpi_id',
    key: 'satuan_kpi_id',
  },
  {
    title: 'Bobot',
    dataIndex: 'bobot',
    key: 'bobot',
    align: 'right',
  },
  {
    title: 'Target',
    dataIndex: 'target_tahunan',
    key: 'target_tahunan',
    align: 'right',
  },
  {
    title: '',
    dataIndex: 'target',
    key: 'target',
    align: 'right',
  },
  {
    title: 'Status',
    dataIndex: 'target_status',
    key: 'target_status',
  },
  {
    title: '',
    dataIndex: 'target_action',
    key: 'target_action',
  },
  {
    title: 'Realisasi',
    dataIndex: 'realisasi',
    key: 'realisasi',
    align: 'right',
  },
  {
    title: 'Status',
    dataIndex: 'realisasi_status',
    key: 'realisasi_status',
  },
  {
    title: '',
    dataIndex: 'realisasi_action',
    key: 'realisasi_action',
  },
  {
    title: 'Pencapaian',
    dataIndex: 'pencapaian',
    key: 'pencapaian',
    align: 'right',
  },
  {
    title: 'Nilai',
    dataIndex: 'nilai',
    key: 'nilai',
    align: 'right',
  },
];

const numberFormat = new Intl.NumberFormat('id');

const ViewPage = {
  name: 'ViewPage',
  components: { RecordViewWrapper, Main },
  setup() {
    const { state, dispatch } = useStore();
    const crud = computed(() => state.crud.data);
    const isLoading = computed(() => state.crud.loading);
    const months = ['', 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const { params } = useRoute();
    const profile = getItem('profile_plnbb');
    const permissions = getItem('permissions');

    const modalState = reactive({
      isLoading: false,
      visible: false,
      kpi_korporat: '',
      month: '',
      year: null,
      type: '',
    });

    const formModalState = reactive({
      id: null,
      alasan: '',
    });

    const labelCol = {
      lg: 6,
      md: 9,
      xs: 24,
    };

    const wrapperCol = {
      lg: 18,
      md: 15,
      xs: 24,
    };

    const formState = reactive({
      month_year: params.year + '-' + params.month,
      month: params.month,
      year: params.year,
      title: 'Indikator Kinerja',
    });

    onMounted(() => {
      getData();
    });

    const dataSource = computed(() =>
      crud.value.length
        ? crud?.value?.map((data, key) => {
            const {
              id,
              number,
              name,
              satuan_kpi,
              bobot,
              target,
              realisasi,
              pencapaian,
              nilai,
              is_parent,
              target_id,
              realisasi_id,
              formula,
              polarisasi,
              // formula_description,
              jenis_variable_kpi_id,
              target_nominal,
              target_nominal_max,
              target_waktu,
              target_status,
              realisasi_status,
              files,
            } = data;

            let target_color;
            let realisasi_color;

            switch (target_status) {
              case 'CRTD':
                target_color = 'blue';
                break;
              case 'UPDT':
                target_color = 'orange';
                break;
              case 'REL':
                target_color = 'green';
                break;
              case 'UNREL':
                target_color = 'volcano';
                break;
              case 'DEL':
                target_color = 'red';
                break;
            }

            switch (realisasi_status) {
              case 'CRTD':
                realisasi_color = 'blue';
                break;
              case 'UPDT':
                realisasi_color = 'orange';
                break;
              case 'REL':
                realisasi_color = 'green';
                break;
              case 'UNREL':
                realisasi_color = 'volcano';
                break;
              case 'DEL':
                realisasi_color = 'red';
                break;
            }

            let create = checkPermission('create kpi korporat') ? (
              <>
                <router-link class="plus" to={`/kpi/kpi-korporat-add-kpi/${id}/${formState.month}/${formState.year}`}>
                  <sdFeatherIcons type="plus" size={14} title="Tambah Sub KPI" />
                </router-link>
                &nbsp;&nbsp;&nbsp;
              </>
            ) : null;

            let upload = checkPermission('upload file kpi korporat') ? (
              !is_parent ? (
                <>
                  <router-link
                    class="plus"
                    to={`/kpi/kpi-korporat-upload-file/${id}/${formState.month}/${formState.year}`}
                  >
                    <sdFeatherIcons type="upload" size={14} title="Upload File" />
                  </router-link>
                  &nbsp;&nbsp;&nbsp;
                </>
              ) : null
            ) : null;

            let update = checkPermission('update kpi korporat') ? (
              !is_parent ? (
                <>
                  <router-link
                    class="edit"
                    to={`/kpi/kpi-korporat-edit-kpi/${id}/${formState.month}/${formState.year}`}
                  >
                    <sdFeatherIcons type="edit" size={14} title="Ubah KPI" />
                  </router-link>
                  &nbsp;&nbsp;&nbsp;
                </>
              ) : (
                <>
                  <router-link class="edit" to={`/kpi/kpi-korporat-edit/${id}/${formState.month}/${formState.year}`}>
                    <sdFeatherIcons type="edit" size={14} title="Ubah KPI" />
                  </router-link>
                  &nbsp;&nbsp;&nbsp;
                </>
              )
            ) : null;

            let del = checkPermission('delete kpi korporat') ? (
              <a class="delete" onClick={() => handleDeleteKpi(id, name)}>
                <sdFeatherIcons type="trash-2" size={14} title="Hapus KPI" />
              </a>
            ) : null;

            let createTarget = formula ? (
              !target ? (
                checkPermission('create target kpi divisi') && jenis_variable_kpi_id != 3 ? (
                  <router-link
                    class="plus"
                    to={`/kpi/kpi-korporat-add-target/${id}/${formState.month}/${formState.year}`}
                  >
                    <sdFeatherIcons type="plus" size={14} title="Tambah Target" />
                  </router-link>
                ) : null
              ) : null
            ) : null;

            let updateTarget = formula ? (
              target ? (
                target_status != 'REL' ? (
                  checkPermission('update kpi korporat') ? (
                    <>
                      <router-link class="edit" to={`/kpi/kpi-korporat-edit-target/${target_id}`}>
                        <sdFeatherIcons type="edit" size={14} title="Ubah Target" />
                      </router-link>
                      &nbsp;&nbsp;&nbsp;
                    </>
                  ) : null
                ) : null
              ) : null
            ) : null;

            let approveTarget = formula ? (
              target ? (
                target_status != 'REL' ? (
                  checkPermission('approve kpi korporat') ? (
                    <>
                      <a
                        class="edit"
                        onClick={() => handleApprove(target_id, name, formState.month, formState.year, 'target')}
                      >
                        <sdFeatherIcons type="check-square" size={14} title="Approve" />
                      </a>
                      &nbsp;&nbsp;&nbsp;
                    </>
                  ) : null
                ) : null
              ) : null
            ) : null;

            let delTarget = formula ? (
              target ? (
                target_status != 'REL' ? (
                  checkPermission('delete kpi korporat') ? (
                    <a class="delete" onClick={() => handleDeleteTarget(target_id, name)}>
                      <sdFeatherIcons type="trash-2" size={14} title="Hapus Target" />
                    </a>
                  ) : null
                ) : null
              ) : null
            ) : null;

            let unapproveTarget = formula ? (
              target ? (
                target_status == 'REL' ? (
                  checkPermission('approve kpi korporat') ? (
                    <a
                      class="edit"
                      onClick={() => handleUnapprove(target_id, name, formState.month, formState.year, 'target')}
                    >
                      <sdFeatherIcons type="rotate-ccw" size={14} title="Unapprove" />
                    </a>
                  ) : null
                ) : null
              ) : null
            ) : null;

            let createRealisasi = formula ? (
              !realisasi ? (
                checkPermission('create kpi korporat') ? (
                  <router-link
                    class="plus"
                    to={`/kpi/kpi-korporat-add-realisasi/${id}/${formState.month}/${formState.year}`}
                  >
                    <sdFeatherIcons type="plus" size={14} title="Tambah Realisasi" />
                  </router-link>
                ) : null
              ) : null
            ) : null;

            let updateRealisasi = formula ? (
              realisasi ? (
                realisasi_status != 'REL' ? (
                  checkPermission('update kpi korporat') ? (
                    <>
                      <router-link class="edit" to={`/kpi/kpi-korporat-edit-realisasi/${realisasi_id}`}>
                        <sdFeatherIcons type="edit" size={14} title="Ubah Realisasi" />
                      </router-link>
                      &nbsp;&nbsp;&nbsp;
                    </>
                  ) : null
                ) : null
              ) : null
            ) : null;

            let approveRealisasi = formula ? (
              realisasi ? (
                realisasi_status != 'REL' ? (
                  checkPermission('approve kpi korporat') ? (
                    <>
                      <a
                        class="edit"
                        onClick={() => handleApprove(realisasi_id, name, formState.month, formState.year, 'realisasi')}
                      >
                        <sdFeatherIcons type="check-square" size={14} title="Approve" />
                      </a>
                      &nbsp;&nbsp;&nbsp;
                    </>
                  ) : null
                ) : null
              ) : null
            ) : null;

            let delRealisasi = formula ? (
              realisasi ? (
                realisasi_status != 'REL' ? (
                  checkPermission('delete kpi korporat') ? (
                    <a class="delete" onClick={() => handleDeleteRealisasi(realisasi_id, name)}>
                      <sdFeatherIcons type="trash-2" size={14} title="Hapus Realisasi" />
                    </a>
                  ) : null
                ) : null
              ) : null
            ) : null;

            let unapproveRealisasi = formula ? (
              realisasi ? (
                realisasi_status == 'REL' ? (
                  checkPermission('approve kpi korporat') ? (
                    <a
                      class="edit"
                      onClick={() => handleUnapprove(realisasi_id, name, formState.month, formState.year, 'realisasi')}
                    >
                      <sdFeatherIcons type="rotate-ccw" size={14} title="Unapprove" />
                    </a>
                  ) : null
                ) : null
              ) : null
            ) : null;

            let deleteFile = file => {
              if (checkPermission('delete file kpi korporat')) {
                return (
                  <a class="delete" onClick={() => handleDeleteFile(file.id, file.title)}>
                    <sdFeatherIcons type="trash-2" size={14} title="Hapus Lampiran" />
                  </a>
                );
              } else {
                return null;
              }
            };

            let downloadFile = file => {
              if (checkPermission('download file kpi korporat')) {
                return (
                  <a title={file.title} onClick={() => handleDownload(file.id, file.title)}>
                    {file.title.length > 20 ? file.title.substring(0, 20) + '...' : file.title}
                  </a>
                );
              } else {
                return <>{file.title.length > 20 ? file.title.substring(0, 20) + '...' : file.title}</>;
              }
            };

            let fileList = files?.map((file, key) => {
              return (
                <div key={key}>
                  <sdFeatherIcons
                    type="paperclip"
                    size={14}
                    title="Lampiran"
                    style="margin-right: 5px; color: #ADB4D2;"
                  />
                  {downloadFile(file)}
                  {deleteFile(file)}
                </div>
              );
            });

            return {
              key: key + 1,
              number,
              name: formula ? (
                <div>
                  <router-link to={`/kpi/kpi-korporat-table/${id}/${formState.month}/${formState.year}`}>
                    {name}
                  </router-link>
                  <br />
                  <br />
                  {fileList}
                </div>
              ) : (
                name
              ),
              kpi_action: (
                <div class="table-actions">
                  {create}
                  {upload}
                  {update}
                  {del}
                </div>
              ),
              polarisasi,
              // formula_description,
              satuan_kpi_id: satuan_kpi ? satuan_kpi['name'] : '',
              bobot: jenis_variable_kpi_id == 3 ? 'Max ' + numberFormat.format(bobot) : numberFormat.format(bobot),
              target_tahunan:
                jenis_variable_kpi_id != 1
                  ? target_waktu
                  : polarisasi != 'Rentang'
                  ? numberFormat.format(target_nominal)
                  : numberFormat.format(target_nominal) + '-' + numberFormat.format(target_nominal_max),
              target:
                jenis_variable_kpi_id == 2 ? (
                  target ? (
                    <router-link
                      to={`/kpi/kpi-korporat-detail-target/${target_id}/${formState.month}/${formState.year}`}
                    >
                      {moment(target).format('MMM YYYY')}
                    </router-link>
                  ) : (
                    ''
                  )
                ) : !target ? (
                  ''
                ) : polarisasi != 'Rentang' ? (
                  <router-link to={`/kpi/kpi-korporat-detail-target/${target_id}/${formState.month}/${formState.year}`}>
                    {numberFormat.format(target)}
                  </router-link>
                ) : (
                  <router-link to={`/kpi/kpi-korporat-detail-target/${target_id}/${formState.month}/${formState.year}`}>
                    {target}
                  </router-link>
                ),
              target_status: target_status ? <a-tag color={target_color}>{target_status}</a-tag> : '',
              target_action: (
                <div class="table-actions">
                  {createTarget}
                  {updateTarget}
                  {approveTarget}
                  {delTarget}
                  {unapproveTarget}
                </div>
              ),
              realisasi:
                jenis_variable_kpi_id == 2 ? (
                  realisasi ? (
                    <router-link
                      to={`/kpi/kpi-korporat-detail-realisasi/${realisasi_id}/${formState.month}/${formState.year}`}
                    >
                      {moment(realisasi).format('MMM YYYY')}
                    </router-link>
                  ) : (
                    ''
                  )
                ) : realisasi ? (
                  <router-link
                    to={`/kpi/kpi-korporat-detail-realisasi/${realisasi_id}/${formState.month}/${formState.year}`}
                  >
                    {numberFormat.format(realisasi)}
                  </router-link>
                ) : (
                  ''
                ),
              realisasi_status: realisasi_status ? <a-tag color={realisasi_color}>{realisasi_status}</a-tag> : '',
              realisasi_action: (
                <div class="table-actions">
                  {createRealisasi}
                  {updateRealisasi}
                  {approveRealisasi}
                  {delRealisasi}
                  {unapproveRealisasi}
                </div>
              ),
              pencapaian: pencapaian ? (jenis_variable_kpi_id == 3 ? pencapaian : pencapaian + '%') : '',
              nilai,
              is_parent,
            };
          })
        : [],
    );

    const handleMonthChange = () => {
      const my = formState.month_year.split('-');
      formState.month = my[1];
      formState.year = my[0];

      getData();
    };

    const getData = () => {
      formState.title = 'Indikator Kinerja ' + months[parseInt(formState.month)] + ' ' + formState.year;

      columns[6].title = 'Target ' + parseInt(formState.year);
      columns[7].title = 'Target ' + months[parseInt(formState.month)];
      columns[10].title = 'Realisasi ' + months[parseInt(formState.month)];

      dispatch('axiosCrudGetData', 'list-kpi-korporat?month=' + formState.month + '&year=' + formState.year);
    };

    const handleDeleteTarget = (id, kpi_korporat) => {
      Modal.confirm({
        title: 'Konfirmasi',
        content: `Apakah Anda yakin ingin menghapus target ${kpi_korporat} ${months[parseInt(formState.month)]} ${
          formState.year
        }?`,
        okText: 'Hapus',
        cancelText: 'Batal',
        onOk: () => {
          dispatch('axiosDataDelete', {
            id,
            url: 'target-kpi-korporat',
            getData: () => {
              dispatch('axiosCrudGetData', 'list-kpi-korporat?month=' + formState.month + '&year=' + formState.year);
            },
          });
        },
      });
    };

    const handleDeleteRealisasi = (id, kpi_korporat) => {
      Modal.confirm({
        title: 'Konfirmasi',
        content: `Apakah Anda yakin ingin menghapus realisasi ${kpi_korporat} ${months[parseInt(formState.month)]} ${
          formState.year
        }?`,
        okText: 'Hapus',
        cancelText: 'Batal',
        onOk: () => {
          dispatch('axiosDataDelete', {
            id,
            url: 'laporan-kpi-korporat',
            getData: () => {
              dispatch('axiosCrudGetData', 'list-kpi-korporat?month=' + formState.month + '&year=' + formState.year);
            },
          });
        },
      });
    };

    const handleDeleteKpi = (id, name) => {
      Modal.confirm({
        title: 'Konfirmasi',
        content: `Apakah Anda yakin ingin menghapus ${name}?`,
        okText: 'Hapus',
        cancelText: 'Batal',
        onOk: () => {
          dispatch('axiosDataDelete', {
            id,
            url: 'kpi-korporat',
            getData: () => {
              dispatch('axiosCrudGetData', 'list-kpi-korporat?month=' + formState.month + '&year=' + formState.year);
            },
          });
        },
      });
    };

    const handleApprove = (id, kpi_korporat, month, year, type) => {
      Modal.confirm({
        title: 'Konfirmasi',
        content: `Apakah Anda yakin ingin melakukan approve ${type} ${kpi_korporat} ${
          months[parseInt(month)]
        } ${year}?`,
        okText: 'Approve',
        cancelText: 'Batal',
        onOk: () => {
          dispatch('axiosDataApprove', {
            id,
            url: 'approve-' + type + '-kpi-korporat',
            getData: () => {
              getData();
            },
          });
        },
      });
    };

    const handleUnapprove = (id, kpi_korporat, month, year, type) => {
      getData();
      formModalState.id = id;
      modalState.kpi_korporat = kpi_korporat;
      modalState.month = months[month];
      modalState.year = year;
      modalState.visible = true;
      modalState.type = type;
    };

    const handleCancel = () => {
      getData();
      modalState.visible = false;
    };

    const doUnapprove = () => {
      modalState.isLoading = true;

      DataService.post('unapprove-' + modalState.type + '-kpi-korporat/' + formModalState.id, {
        alasan: formModalState.alasan,
      }).then(() => {
        modalState.visible = false;
        modalState.isLoading = false;
        formModalState.id = null;
        formModalState.alasan = '';

        getData();
      });
    };

    const modalRules = {
      alasan: [
        {
          required: true,
          message: 'Harap Diisi',
          trigger: 'blur',
        },
      ],
    };

    const checkPermission = permission => {
      if (profile.roles[0].name == 'Super Admin') {
        return true;
      }

      return permissions.includes(permission);
    };

    const handleDownload = (id, title) => {
      axios({
        url: `${process.env.VUE_APP_API_ENDPOINT}/kpi-korporat-file/${id}`,
        method: 'GET',
        responseType: 'blob', // important
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('access_token'),
        },
      })
        .then(response => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;

          const contentDisposition = response.headers['content-disposition'];
          let fileName = title;
          if (contentDisposition) {
            const fileNameMatch = contentDisposition.match(/filename="(.+)"/);
            if (fileNameMatch.length === 2) {
              fileName = fileNameMatch[1];
            }
          }

          link.setAttribute('download', fileName);
          document.body.appendChild(link);
          link.click();
        })
        .catch(error => {
          console.log(error);
        });
    };

    const handleDeleteFile = (id, title) => {
      Modal.confirm({
        title: 'Konfirmasi',
        content: `Apakah Anda yakin ingin menghapus file ${title}?`,
        okText: 'Hapus',
        cancelText: 'Batal',
        onOk: () => {
          dispatch('axiosDataDeleteFile', {
            id,
            url: 'kpi-korporat-file',
            getData: () => {
              dispatch('axiosCrudGetData', 'list-kpi-korporat?month=' + formState.month + '&year=' + formState.year);
            },
          });
        },
      });
    };

    return {
      columns,
      isLoading,
      crud,
      dataSource,
      formState,
      handleMonthChange,
      labelCol,
      wrapperCol,
      handleDeleteTarget,
      handleDeleteRealisasi,
      handleDeleteKpi,
      handleApprove,
      handleUnapprove,
      handleCancel,
      doUnapprove,
      modalState,
      formModalState,
      modalRules,
      checkPermission,
      handleDownload,
      handleDeleteFile,
    };
  },
};

export default ViewPage;
</script>
