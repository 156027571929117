<template>
  <div>
    <sdPageHeader title="Indikator Kinerja" />
    <Main>
      <a-row :gutter="25">
        <a-col :sm="24" :xs="24">
          <sdCards headless>
            <a-form
              layout="horizontal"
              :model="formState"
              :label-col="labelCol"
              :wrapper-col="wrapperCol"
              labelAlign="left"
            >
              <a-form-item name="month_year" label="Bulan" style="margin-bottom: 0;">
                <a-month-picker
                  v-model:value="formState.month_year"
                  placeholder="Pilih Bulan"
                  format="MM-YYYY"
                  valueFormat="YYYY-MM"
                  @change="handleMonthChange"
                  style="width: 100%"
                />
              </a-form-item>
            </a-form>
          </sdCards>
        </a-col>
      </a-row>
      <a-row :gutter="25">
        <a-col :sm="8" :xs="8">
          <RatioCard>
            <sdCards headless title="Nilai Kinerja Organisasi">
              <div class="ratio-content">
                <a-row :gutter="0">
                  <a-col :sm="10" :xs="10">
                    <a @click="showHistoryKpi">
                      <sdHeading as="h1" class="big-number">{{ dashData.pencapaian }}%</sdHeading>
                    </a>
                  </a-col>
                  <a-col :sm="14" :xs="14">
                    <vue-gauge :refid="'nko'" :options="dashData.gaugeOptions"> </vue-gauge>
                  </a-col>
                </a-row>
                <a-progress :percent="dashData.pencapaian" :class="'progress-' + dashData.color" :showInfo="false" />
              </div>
            </sdCards>
          </RatioCard>
        </a-col>

        <a-col :sm="8" :xs="8" v-for="kpiKorporat in dashData.kpiKorporats" :key="kpiKorporat.id">
          <RatioCard>
            <sdCards headless :title="kpiKorporat.name">
              <div class="ratio-content">
                <!-- <a-row :gutter="25"> -->
                <!-- <a-col :sm="12" :xs="12"> -->
                <a @click="showDetailKpi(kpiKorporat.id, kpiKorporat.name)" class="text-dark">
                  <sdHeading as="h1">{{ kpiKorporat.pencapaian }}%</sdHeading>
                </a>
                <!-- </a-col> -->
                <!-- <a-col :sm="12" :xs="12">
                    <vue-gauge :refid="'kpiKorporat' + kpiKorporat.id"
                                 :options="{
                      needleValue: kpiKorporat.pencapaian, needleColor: 'rgba(0, 0, 0, 0.8)',
                      arcDelimiters: [17, 34, 51, 68, 85], arcColors: ['#FF0000', '#FF4500', '#FFA500', '#ADFF2F'
                        , '#008000', '#006400'],
                    }">
                    </vue-gauge>
                  </a-col> -->
                <!-- </a-row> -->

                <a-progress
                  :percent="kpiKorporat.pencapaian"
                  :class="'progress-' + kpiKorporat.color"
                  :showInfo="false"
                />

                <ExList>
                  <a-row :gutter="25">
                    <a-col :sm="12" :xs="12">
                      <p>Bobot</p>
                      <span>{{ kpiKorporat.bobot }}</span>
                    </a-col>
                    <a-col :sm="12" :xs="12">
                      <p>Nilai</p>
                      <span>{{ kpiKorporat.nilai }}</span>
                    </a-col>
                  </a-row>
                </ExList>
              </div>
            </sdCards>
          </RatioCard>
        </a-col>
      </a-row>
      <a-row :gutter="25">
        <a-col :sm="24" :xs="24">
          <div class="full-width-table">
            <sdCards
              :title="
                'Laporan Pencapaian Indikator Kinerja ' + months[parseInt(formState.month)] + ' ' + formState.year
              "
            >
              <div>
                <a-table
                  :columns="columns"
                  :dataSource="dataSource"
                  :pagination="false"
                  :rowClassName="record => (record.is_parent ? 'text-strong' : '')"
                />
              </div>
            </sdCards>
          </div>
        </a-col>
      </a-row>
    </Main>
  </div>

  <a-modal :visible="detail.visible" :title="detail.title" :onOk="handleOk" :onCancel="handleCancel">
    <div class="card-chunk">
      <vue3-chart-js
        :id="detail.id"
        ref="chartRef"
        :type="detail.type"
        :data="detail.data"
        :options="detail.options"
      ></vue3-chart-js>
    </div>
  </a-modal>

  <a-modal
    :visible="detailKpi.visible"
    :title="detailKpi.title"
    :onOk="handleOkKpi"
    :onCancel="handleCancelKpi"
    :width="1000"
  >
    <div class="card-chunk">
      <TableWrapper class="table-data-view table-responsive">
        <a-table :columns="columnsKpi" :pagination="false" :dataSource="dataSourceKpi" />
      </TableWrapper>
    </div>
  </a-modal>

  <a-modal :visible="historyKpi.visible" :title="historyKpi.title" :width="1000" :onCancel="handleCancelHistory">
    <div class="card-chunk">
      <TableWrapper class="table-data-view table-responsive">
        <a-table :columns="columnsHistory" :pagination="false" :dataSource="dataSourceHistory" />
      </TableWrapper>
    </div>
    <template #footer>
      <a-button class="sDash_form-action__btn" type="primary" htmlType="button" @click="handleOkHistory">
        OK
      </a-button>
    </template>
  </a-modal>
</template>

<script>
import { Main } from '../styled';
import { RatioCard, ExList } from '../dashboard/style';
import { reactive, onMounted, computed, ref } from 'vue';
import { useStore } from 'vuex';
import moment from 'moment';
import Vue3ChartJs from '@j-t-mcc/vue3-chartjs';
import VueGauge from 'vue-gauge';
import { TableWrapper } from '../styled';
let GaugeChart = require('vue-gauge/assets/bundle.js');

export default {
  name: 'Indikator Kinerja',
  components: {
    Main,
    RatioCard,
    ExList,
    Vue3ChartJs,
    VueGauge,
    TableWrapper,
  },
  setup() {
    const { state, dispatch } = useStore();
    const dateObj = new Date();
    const month = dateObj.getUTCMonth() + 1;
    const isLoading = computed(() => state.crud.loading);
    const crud = computed(() => state.crud.data);
    const crudDetail = computed(() => state.crud.detail);
    const crudHistory = computed(() => state.crud.detail);
    const dashData = reactive({
      kpiKorporats: [],
      nilai: 0,
      pencapaian: 0,
      color: null,
      gaugeOptions: {
        needleValue: 0,
        hasNeedle: true,
        needleColor: 'rgba(0, 0, 0, 0.8)',
        arcDelimiters: [17, 34, 51, 68, 85],
        arcColors: ['#FF0000', '#FF4500', '#FFA500', '#ADFF2F', '#008000', '#006400'],
        rangeLabel: ['0', '100'],
      },
    });
    const months = ['', 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const detail = reactive({
      visible: false,
      title: null,
      id: 'bar',
      type: 'bar',
      data: {
        labels: [],
        datasets: [],
      },
      options: {
        legend: {
          display: true,
          labels: {
            display: true,
          },
        },
        scales: {
          yAxes: [
            {
              stacked: true,
              gridLines: {
                display: false,
              },
              ticks: {
                display: true,
              },
            },
          ],
          xAxes: [
            {
              stacked: true,
              gridLines: {
                display: false,
              },
              ticks: {
                display: true,
              },
            },
          ],
        },
      },
    });

    const detailKpi = reactive({
      visible: false,
      title: null,
    });

    const historyKpi = reactive({
      visible: false,
      title: null,
    });

    const chartRef = ref(null);

    const labelCol = {
      lg: 6,
      md: 9,
      xs: 24,
    };

    const wrapperCol = {
      lg: 18,
      md: 15,
      xs: 24,
    };

    const formState = reactive({
      month_year: dateObj.getUTCFullYear() + '-' + ('0' + month).slice(-2),
      month: null,
      year: null,
    });

    const handleMonthChange = () => {
      getData();
    };

    const columns = [
      {
        title: '#',
        dataIndex: 'number',
        key: 'number',
        align: 'right',
      },
      {
        title: 'Indikator Kinerja',
        dataIndex: 'name',
        key: 'name',
      },
      {
        title: 'Satuan',
        dataIndex: 'satuan_kpi_id',
        key: 'satuan_kpi_id',
      },
      {
        title: 'Bobot',
        dataIndex: 'bobot',
        key: 'bobot',
        align: 'right',
      },
      {
        title: 'Target',
        dataIndex: 'target',
        key: 'target',
        align: 'right',
      },
      {
        title: 'Realisasi',
        dataIndex: 'realisasi',
        key: 'realisasi',
        align: 'right',
      },
      {
        title: 'Nilai',
        dataIndex: 'nilai',
        key: 'nilai',
        align: 'right',
      },
      {
        title: 'Pencapaian',
        dataIndex: 'pencapaian',
        key: 'pencapaian',
      },
    ];

    const columnsKpi = [
      {
        title: '#',
        dataIndex: 'number',
        key: 'number',
        align: 'right',
      },
      {
        title: 'Indikator Kinerja',
        dataIndex: 'name',
        key: 'name',
      },
      {
        title: 'Satuan',
        dataIndex: 'satuan_kpi_id',
        key: 'satuan_kpi_id',
      },
      {
        title: 'Bobot',
        dataIndex: 'bobot',
        key: 'bobot',
        align: 'right',
      },
      {
        title: 'Target',
        dataIndex: 'target',
        key: 'target',
        align: 'right',
      },
      {
        title: 'Realisasi',
        dataIndex: 'realisasi',
        key: 'realisasi',
        align: 'right',
      },
      {
        title: 'Pencapaian',
        dataIndex: 'pencapaian',
        key: 'pencapaian',
        align: 'right',
      },
      {
        title: 'Nilai',
        dataIndex: 'nilai',
        key: 'nilai',
        align: 'right',
      },
    ];

    const columnsHistory = [
      {
        title: 'Tahun',
        dataIndex: 'year',
        key: 'year',
        align: 'center',
      },
      {
        title: 'Nilai Kinerja Organisasi',
        dataIndex: 'nko',
        key: 'nko',
        align: 'center',
      },
    ];

    const dataSource = computed(() =>
      crud.value.listKpiKorporats && crud.value.listKpiKorporats.length
        ? crud.value.listKpiKorporats.map((data, key) => {
            const {
              id,
              number,
              name,
              satuan_kpi,
              bobot,
              target,
              target_nominal,
              target_waktu,
              jenis_variable_kpi_id,
              realisasi,
              pencapaian,
              nilai,
              is_parent,
              color,
              formula,
            } = data;

            return {
              key: key + 1,
              number,
              name: !formula ? (
                name
              ) : (
                <a onClick={() => showDetail(id, name)} class="text-dark">
                  {name}
                </a>
              ),
              satuan_kpi_id: satuan_kpi ? satuan_kpi['name'] : '',
              bobot: jenis_variable_kpi_id == 3 ? 'Max ' + bobot : bobot,
              pencapaian: is_parent ? null : jenis_variable_kpi_id != 3 ? (
                <a-progress
                  percent={parseFloat(pencapaian)}
                  strokeWidth={5}
                  status="active"
                  class={'progress-' + color}
                />
              ) : null,
              nilai,
              target: is_parent
                ? jenis_variable_kpi_id == 1
                  ? target_nominal
                  : jenis_variable_kpi_id == 2
                  ? target_waktu
                    ? moment(target_waktu).format('DD MMM YYYY')
                    : '-'
                  : undefined
                : jenis_variable_kpi_id == 1
                ? target
                : jenis_variable_kpi_id == 2
                ? target
                  ? moment(target).format('DD MMM YYYY')
                  : '-'
                : undefined,
              realisasi:
                jenis_variable_kpi_id == 2 ? (realisasi ? moment(realisasi).format('DD MMM YYYY') : '-') : realisasi,
              is_parent,
            };
          })
        : [],
    );

    onMounted(() => {
      getData();
    });

    const getData = () => {
      const my = formState.month_year.split('-');
      formState.month = my[1];
      formState.year = my[0];

      dispatch('axiosCrudGetData', 'dashboard/kpi-korporat?month=' + formState.month + '&year=' + formState.year).then(
        () => {
          dashData.kpiKorporats = crud.value.kpiKorporats;
          dashData.nilai = crud.value.nilai;
          dashData.pencapaian = crud.value.pencapaian;

          // update gauge needle
          let element = document.querySelector('#nko');
          let [svg] = element.getElementsByTagName('svg');
          svg.remove();
          GaugeChart.gaugeChart(element, 250, dashData.gaugeOptions).updateNeedle(crud.value.pencapaian);

          if (crud.value.pencapaian >= 100) {
            dashData.color = 'success';
          } else if (crud.value.pencapaian >= 95 && crud.value.pencapaian < 100) {
            dashData.color = 'warning';
          } else if (crud.value.pencapaian < 95) {
            dashData.color = 'danger';
          }
        },
      );
    };

    const showDetail = (id, name) => {
      dispatch(
        'axiosCrudGetDataDetail',
        'dashboard/kpi-korporat-detail?month=' + formState.month + '&year=' + formState.year + '&kpi_korporat_id=' + id,
      ).then(() => {
        detail.data.labels = crudDetail.value.labels;

        detail.data.datasets = [
          {
            type: 'line',
            data: crudDetail.value.targets,
            backgroundColor: 'rgba(0,0,0,0)',
            hoverBackgroundColor: '#FF4D4F',
            borderColor: '#FF4D4F',

            lineTension: 0,
            label: 'Target',
          },
          {
            type: 'bar',
            data: crudDetail.value.realisasis,
            backgroundColor: '#20C997',
            hoverBackgroundColor: '#1cb487',
            label: 'Realisasi',
          },
        ];

        chartRef.value.update(250);
      });

      detail.title = name;

      detail.visible = true;
    };

    const dataSourceKpi = computed(() =>
      crudDetail.value.length
        ? crudDetail.value.map((data, key) => {
            const { number, name, satuan_kpi, bobot, target, realisasi, pencapaian, nilai, is_parent } = data;

            return {
              key: key + 1,
              number,
              name,
              satuan_kpi_id: satuan_kpi ? satuan_kpi['name'] : '',
              bobot,
              target,
              realisasi,
              pencapaian,
              nilai,
              is_parent,
            };
          })
        : [],
    );

    const dataSourceHistory = computed(() =>
      crudHistory.value.length
        ? crudHistory.value.map(data => {
            const { year, nko } = data;

            return {
              year,
              nko: nko > 0 ? nko + '%' : '-',
            };
          })
        : [],
    );

    const showDetailKpi = (id, name) => {
      dispatch(
        'axiosCrudGetDataDetail',
        'list-kpi-korporat?month=' + formState.month + '&year=' + formState.year + '&parent_id=' + id,
      );

      detailKpi.title = name;

      detailKpi.visible = true;
    };

    const showHistoryKpi = () => {
      dispatch('axiosCrudGetDataDetail', 'history-nko');

      historyKpi.title = 'Riwayat Nilai Kinerja Organisasi';

      historyKpi.visible = true;
    };

    const handleOk = () => {
      detail.visible = false;
    };

    const handleCancel = () => {
      detail.visible = false;
    };

    const handleOkKpi = () => {
      detailKpi.visible = false;
    };

    const handleCancelKpi = () => {
      detailKpi.visible = false;
    };

    const handleOkHistory = () => {
      historyKpi.visible = false;
    };

    const handleCancelHistory = () => {
      historyKpi.visible = false;
    };

    return {
      formState,
      handleMonthChange,
      labelCol,
      wrapperCol,
      dashData,
      isLoading,
      crud,
      crudDetail,
      crudHistory,
      months,
      columns,
      columnsKpi,
      columnsHistory,
      dataSource,
      dataSourceKpi,
      dataSourceHistory,
      detail,
      detailKpi,
      historyKpi,
      handleOk,
      handleCancel,
      handleOkKpi,
      handleCancelKpi,
      handleOkHistory,
      handleCancelHistory,
      chartRef,
      showDetailKpi,
      showHistoryKpi,
    };
  },
};
</script>
